<template>
  <!-- <b-container class="mt-3"> -->
  <b-card class="border-none rounded-xl px-3 shadow-sm card-reward" no-body>
    <div class="text-center mt-3">
      <b class="f-xl">{{ `สแกนลุ้นรับกับ ${$BRAND}` }}</b>

      <div class="text-gray mt-2">
        <small>{{ getRewardList[count].redeem_code }}</small>
      </div>
    </div>
    <div class="rounded-xl p-3 overflow-hidden contant-reward">
      <lottie-player
        autoplay
        :loop="true"
        mode="normal"
        background="transparent"
        direction="1"
        class="box-animation"
        src="https://lottie.host/24a51c54-51a7-44dc-80da-8d6b46e6dc48/1CMGhFJWGN.json"
        style="width: 100%; height: 300px"
      ></lottie-player>

      <p class="text-center mt-3 f-md" id="previewMessage">
        ถ้าเตรียมดวงพร้อมแล้ว "เปิดกล่องรางวัล" เพื่อลุ้นโชคกันเลย
      </p>
      <div class="text-center">
        <b-button
          pill
          variant="confirm-primary"
          class="shadow-box mb-2 mt-0 btn-open-box"
          @click="$emit('next')"
        >
          เปิดกล่องรางวัล
        </b-button>

        <div
          class="text-gray cursor-pointer"
          v-if="countList.length - (count + 1) != 0"
          @click="$emit('openAll')"
        >
          <small>
            <u>เปิดทั้งหมด ({{ countList.length - count }})</u>
          </small>
        </div>
        <div style="height: 24px" v-else></div>
      </div>
    </div>
  </b-card>

  <!-- </b-container> -->
</template>

<script>
require("@lottiefiles/lottie-player");
// import * as LottiePlayer from "@dotlottie/player-component";
const box = require("@/assets/images/box3-f.gif");
const boxF = require("@/assets/images/box3-f2.gif");
var timeout = null;
export default {
  // components: {
  //   LottiePlayer
  // },
  props: {
    countList: { required: true },
    getRewardList: { required: true },
    count: { required: true }
  },
  data() {
    return {};
  },
  methods: {
    rewardImage(items) {
      if (items) {
        if (items.random_reward_item_type == 4)
          return require("@/assets/images/point.png");
        if (items.random_reward_item_type == 1)
          return require("@/assets/images/Fail.png");
        if (items.item_img) return items.item_img;
        return require("@/assets/images/default-image.png");
      }
      return require("@/assets/images/default-image.png");
    }
  }
};
</script>

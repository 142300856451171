<template>
  <div class="container-transaction">
    <Loading v-if="isLoading" />
    <div v-else>
      <NissinView
        v-if="view"
        @showLoading="showLoading"
        @hideLoading="hideLoading"
        :profile="profile"
      />
      <DefaultView
        v-else
        @showLoading="showLoading"
        @hideLoading="hideLoading"
        :profile="profile"
      />
    </div>
  </div>
</template>
<script>
import DefaultView from "./defaultView.vue";
import NissinView from "./nissinView.vue";
import Loading from "@/components/other/Loading";

export default {
  components: { NissinView, DefaultView, Loading },
  data() {
    return {
      profile: {},
      isLoading: false,
      view: process.env.VUE_APP_BRAND == "Nissin"
    };
  },

  async mounted() {
    this.liffLogin();
  },
  methods: {
    showLoading() {
      this.isLoading = true;
    },
    hideLoading() {
      this.isLoading = false;
    },
    async liffLogin() {
      this.isLoading = true;
      let endpoint = "transaction";

      if (this.$route.query.code || this.$route.params.transactionCode) {
        endpoint += `?code=${this.$route.query.code}&redeemId=${this.$route.query.redeemId}`;
      }

      await this.$store.dispatch("lineLiffLogin", this.$Endpoint + endpoint);
      const profile = await this.$store.dispatch("getUserApi");
      this.profile = profile;
      this.isLoading = false;
    }
  }
};
</script>
<style lang="scss">

</style>

<template>
  <b-container class="mt-3">
    <!-- <b-card class="border-none rounded-xl px-3 shadow-sm" no-body> -->
    <Step1
      v-if="step == 1"
      :count="count"
      :countList="countList"
      :getRewardList="getRewardList"
      @next="next"
      @openAll="openAll"
    />
    <Step2
      v-else-if="step == 2"
      :count="count"
      :countList="countList"
      :getRewardList="getRewardList"
      @next="next"
    />
    <Step3
      v-else-if="step == 3"
      :count="count"
      :countList="countList"
      :getRewardList="getRewardList"
      @next="next"
    />
    <Step4
      v-else-if="step == 4"
      :count="count"
      :countList="countList"
      :getRewardList="getRewardList"
      @next="next"
      @nextBox="nextBox"
      @lastBox="lastBox"
      @openAll="openAll"
      @resend="resend"
      :point_total="point_total"
      @updatePoint="val => $emit('updatePoint', val)"
    />

    <Modal
      :getRewardList="getRewardList"
      ref="reward-modal"
      :skipBox="skipBox"
      @resend="resend"
    />
  </b-container>
</template>

<script>
import Step1 from "./components/step1";
import Step2 from "./components/step2";
import Step3 from "./components/step3";
import Step4 from "./components/step4";
import Modal from "./components/modal";

export default {
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Modal
  },
  props: {
    countList: { required: true },
    getRewardList: { required: true },
    point_total: { required: true }
  },
  data() {
    return {
      step: 1,
      count: 0,
      skipBox: false
    };
  },

  methods: {
    next(val) {
      if (
        this.step == 2 &&
        this.getRewardList[this.count].random_reward_item_type == 1
      ) {
        this.step = 4;
        return;
      }
      if (val) {
        this.step = 4;
        return;
      }
      this.step += 1;
    },
    nextBox() {
      this.step = 1;
      this.count += 1;
    },
    lastBox() {
      this.$refs["reward-modal"].show();
    },
    openAll() {
      this.$refs["reward-modal"].show();
      this.skipBox = true;
    },
    resend() {
      this.step = 1;
      this.count = 0;
      this.skipBox = false;
      this.$emit("resend");
    }
  }
};
</script>

<style lang="scss">
@import "style.scss";
</style>

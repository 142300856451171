<template>
  <b-card class="border-none rounded-xl px-3 shadow-sm card-reward" no-body>
    <div class="text-center mt-3">
      <b class="f-xl">{{ `สแกนลุ้นรับกับ ${$BRAND}` }}</b>

      <div class="text-gray mt-2">
        <small>{{ getRewardList[count].redeem_code }}</small>
      </div>
    </div>
    <div class="rounded-xl p-3 overflow-hidden contant-reward">
      <lottie-player
        autoplay
        :loop="true"
        mode="normal"
        background="transparent"
        direction="1"
        class="box-animation"
        src="https://lottie.host/1776e287-8136-49e3-bf99-296c592ad298/gMmOqfbV9c.json"
        style="width: 100%; height: 300px"
      ></lottie-player>

      <p class="text-center mt-3 f-md" id="previewMessage">
        ถ้าเตรียมดวงพร้อมแล้ว "เปิดกล่องรางวัล" เพื่อลุ้นโชคกันเลย
      </p>
      <div class="text-center">
        <b-button
          pill
          variant="confirm-primary"
          class="shadow-box mb-2 mt-0 btn-open-box"
          @click="skipAnimate()"
        >
          ข้าม
        </b-button>
        <div style="height: 24px"></div>
      </div>
    </div>
  </b-card>
</template>

<script>
require("@lottiefiles/lottie-player");

var timeout = null;
export default {
  // components: {
  //   LottiePlayer
  // },
  props: {
    countList: { required: true },
    getRewardList: { required: true },
    count: { required: true }
  },
  data() {
    return {};
  },
  mounted() {
    timeout = setTimeout(() => {
      this.$emit("next");
    }, 2700);
  },
  methods: {
    skipAnimate() {
      clearTimeout(timeout);
      // this.caseReceiveReward();
      this.$emit("next", true);
    }
  }
};
</script>

<template>
  <div class="px-3 py-2" v-if="!isSend">
    <b-img
      class="banner-image"
      :src="Image1"
      fluid
      alt="Responsive image"
    ></b-img>
    <div class="bg-desc">
      <div class="f-bold text-center">
        เลือกรับสิทธิ์ลุ้นของรางวัลใหญ่ <br />
        NISSIN หรือ BILLKIN
      </div>
    </div>
    <b-row>
      <b-col @click="setSend(8, [55], Image5, 2)">
        <b-img class="banner-image" :src="Image3" fluid alt="Responsive image">
        </b-img>
        <div class="text-white text-center">ส่งรหัส</div>
      </b-col>
      <b-col @click="setSend(8, [54], Image4, 1)">
        <b-img
          class="banner-image"
          :src="Image2"
          fluid
          alt="Responsive image"
        ></b-img>
        <div class="text-white text-center">ส่งรหัส</div>
      </b-col>
    </b-row>
    <div class="bg-gray">
      <div class="text-center">
        <router-link to="/reward?tabIndex=1"
          ><u class="text-white"
            >ดูรายการของรางวัลที่รับสิทธิแล้ว</u
          ></router-link
        >
      </div>

      <div
        @click="$EventBus.$emit('showModal', 'term', 'Nissin', true)"
        class="text-center text-underline text-white"
      >
        <u>ข้อกำหนดและเงื่อนไข</u>
      </div>
    </div>
  </div>
  <Transaction
    v-else
    :profile="profile"
    @back="isSend = false"
    :redeem_id="redeem_id"
    :product_id="product_id"
    :bannerImage="image"
    :type="type"
  />
</template>

<script>
import Transaction from "./transaction";
const Image1 = require("@/assets/images/nissin-1.jpg");
const Image2 = require("@/assets/images/nissin-2.jpg");
const Image3 = require("@/assets/images/nissin-3.jpg");
const Image4 = require("@/assets/images/nissin-4.jpg");
const Image5 = require("@/assets/images/nissin-5.jpg");
export default {
  components: { Transaction },
  props: {
    profile: {
      required: true
    }
  },
  data() {
    return {
      isSend: false,
      Image1,
      Image2,
      Image3,
      Image4,
      Image5,
      redeem_id: 0,
      product_id: 0,
      image: "",
      type: 1
    };
  },
  mounted() {
    // if (this.$route.query.code) {
    //   this.isSend = true;
    // }
  },
  methods: {
    setSend(id, product_id, image, mode) {
      this.isSend = true;
      this.redeem_id = id;
      this.image = image;
      this.product_id = product_id;
      this.type = mode;
    }
  }
};
</script>

<style lang="scss" scoped>
.banner-image {
  width: 100%;
  aspect-ratio: 1;
  border-radius: 1.25rem;
  // background: beige;
}
.bg-desc {
  background-color: rgba(168, 165, 165, 0.438);
  padding: 1rem;
  color: white;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border-radius: 0.5rem;
}
.bg-gray {
  text-shadow: 0px 0px 3px #00000073;
}
</style>

<template>
  <b-card class="border-none rounded-xl px-3 shadow-sm card-reward" no-body>
    <div class="text-center mt-3">
      <b class="f-xl">ยินดีด้วยคุณได้รับรางวัล</b>

      <div class="text-gray mt-2">
        <small>
          {{ getRewardList[count].redeem_code }}
          <span class="text-success">
            :
            <font-awesome-icon
              icon="check-circle"
              class="mx-1 text-success"
            />ได้รับของรางวัล
          </span>
        </small>
      </div>
    </div>
    <div class="rounded-xl p-3 overflow-hidden contant-reward">
      <!-- <lottie-player
        autoplay
        :loop="true"
        mode="normal"
        background="transparent"
        direction="1"
        class="box-animation"
        src="https://lottie.host/1776e287-8136-49e3-bf99-296c592ad298/gMmOqfbV9c.json"
        style="width: 100%; height: 100%"
      ></lottie-player> -->
      <div class="opened-box">
        <b-img
          :src="require('@/assets/images/opened-box.gif')"
          @error="handleImageSrc"
          class="image-reward"
        ></b-img>
      </div>

      <p class="text-center mt-3 f-md" id="previewMessage">
        ดวงของคุณยังดีอยู่ กดดูของรางวัลเพื่อไปต่อได้เลย
      </p>
      <div class="text-center">
        <b-button
          pill
          variant="confirm-primary"
          class="shadow-box mb-2 mt-0 btn-open-box"
          @click="$emit('next')"
        >
          ดูของรางวัล
        </b-button>
        <div style="height: 24px"></div>
      </div>
    </div>
  </b-card>
</template>

<script>
require("@lottiefiles/lottie-player");

export default {
  props: {
    countList: { required: true },
    getRewardList: { required: true },
    count: { required: true }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    rewardImage(items) {
      if (items) {
        if (items.random_reward_item_type == 4)
          return require("@/assets/images/point.png");
        if (items.random_reward_item_type == 1)
          return require("@/assets/images/Fail.png");
        if (items.item_img) return items.item_img;
        return require("@/assets/images/default-image.png");
      }
      return require("@/assets/images/default-image.png");
    }
  }
};
</script>
<style lang="scss">
.opened-box {
  background: whitesmoke;
  border-radius: 0.5rem;
  transition: all 1s;
  text-align: center;
  img {
    max-width: 200px;
  }
}
</style>

<template>
  <div>
    <Loading v-if="isLoading" />
    <div class="px-3">
      <section v-if="!isSend" class="container-input-code">
        <div>
          <b-img
            class="banner-image"
            :src="bannerImage"
            fluid
            alt="Responsive image"
          ></b-img>

          <div class="reward-transaction-desc">
            <div v-if="type == 1">
              <div class="my-4 text-white">
                <div class="f-bold text-center">ของรางวัล BILLKIN</div>
                <div class="d-flex justify-content-center">
                  <div class="p-2">
                    <div class="text-left letter-spacing">
                      1. แท่งไฟ Billkin พร้อมลายเซ็นบิวกิ้น มูลค่า 1,950 บาท
                      จำนวน 5 รางวัล มูลค่ารวม 9,750 บาท
                    </div>
                    <div class="text-left letter-spacing">
                      2. กระบอกน้ำนิสซิน พร้อมลายเซ็นบิวกิ้น มูลค่า 1,200 บาท
                      จำนวน 10 รางวัล มูลค่ารวม 12,000 บาท
                    </div>
                    <div class="text-left letter-spacing">
                      3. เทียนหอม Cup Noodles พร้อมลายเซ็น e-sign บิวกิ้น มูลค่า
                      500 บาท จำนวน 40 รางวัล มูลค่ารวม 20,000 บาท
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="type == 2">
              <div class="my-4 text-white">
                <div class="f-bold text-center">ของรางวัล NISSIN</div>
                <div class="d-flex justify-content-center">
                  <div class="p-2">
                    <div class="text-left letter-spacing">
                      1. รางวัล แพ็คเกจทัวร์ประเทศญี่ปุ่น 5 วัน 3 คืน (สำหรับ 2
                      ท่าน) มูลค่า 135,100 บาท จำนวน 5 รางวัล มูลค่ารวม 675,000
                      บาท
                    </div>
                    <div class="text-left letter-spacing">
                      2. รถจักรยานยนต์ มูลค่า 50,000 บาท จำนวน 5 รางวัล
                      มูลค่ารวม 250,000 บาท
                    </div>
                    <div class="text-left letter-spacing">
                      3. ทองคำหนัก 1 สลึง มูลค่า 8,000 บาท จำนวน 10 รางวัล
                      มูลค่ารวม 80,000 บาท
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-white f-md d-flex align-items-center mb-2">
            กรอกรหัสเพื่อรับสิทธิ์ลุ้นรางวัล
            <font-awesome-icon icon="info-circle" class="ml-2" />
          </div>

          <div>
            <div
              v-for="(qr, index) of qrList"
              :key="'qrcode-' + index"
              class="mb-2"
            >
              <div class="qr-rows">
                <div class="input-qr">
                  <input
                    type="text"
                    v-model.trim="qr.code"
                    @keyup="clearError(index), checkError(qr, index)"
                    @input="clearError(index), checkError(qr, index)"
                    @paste="clearError(index), checkError(qr, index)"
                    :ref="'input-qrcode-' + index"
                    :class="[
                      qr.error && qr.error != 'used' ? 'text-danger' : ''
                    ]"
                  />

                  <font-awesome-icon
                    icon="times"
                    v-if="qr.code != '' && qr.error && qr.error != 'used'"
                  />

                  <font-awesome-icon
                    icon="check"
                    v-else-if="qr.error == 'used'"
                  />
                  <div v-if="qr.errorWithMessage" class="text-danger t-nowrap">
                    โค้ดซ้ำ
                  </div>
                </div>
                <label :for="'files' + index" class="btn">
                  <font-awesome-icon
                    icon="camera"
                    class="ml-2 color-primary cursor-pointer"
                /></label>

                <input
                  type="file"
                  :id="'files' + +index"
                  @change="readURL($event, index)"
                  style="visibility: hidden; position: absolute"
                  accept="image/*"
                  :capture="device"
                />
              </div>
            </div>
          </div>
          <div class="text-center my-3" v-show="qrList.length < 10">
            <b-button
              variant="round-icon"
              class="shadow-box"
              @click="addQrList()"
              :disabled="disableAddButton"
            >
              <font-awesome-icon icon="plus" />
            </b-button>
          </div>
        </div>
        <section class="px-2 mb-2">
          <div class="text-center">
            <div>
              <b-button
                pill
                variant="primary-theme"
                @click="confirmSendCode()"
                :disabled="disableButton || disableTimeButton || isLoading"
              >
                {{
                  disableTimeButton
                    ? `กรุณารอสักครู่ ... ${timeCountDown}`
                    : "ยืนยันการส่งรหัส"
                }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="link"
                class="text-white f-md"
                @click="$emit('back')"
              >
                ย้อนกลับ
              </b-button>
            </div>
          </div>
        </section>
      </section>
      <section v-else>
        <div class="banner-image bg-banner p-3" v-if="countEarnReward == 0">
          <font-awesome-icon icon="times" />

          <div class="text-center">{{ errorMesasge }}</div>
        </div>
        <b-img
          v-else
          class="banner-image"
          :src="earnReward"
          fluid
          alt="response image"
        ></b-img>

        <div class="text-center text-white my-3 background-earn">
          ผลการตรวจสอบรหัส <br />
          สิทธิที่ได้รับ
          <br />
          <span class="f-48">{{ countEarnReward }}</span>
        </div>

        <div class="qr-list-container">
          <div
            v-for="(qr, index) of qrList"
            :key="'qrcode-' + index"
            class="mb-2"
          >
            <div class="qr-rows">
              <div class="input-qr">
                <input
                  type="text"
                  v-model.trim="qr.code"
                  @keyup="clearError(index), checkError(qr, index)"
                  @input="clearError(index), checkError(qr, index)"
                  @paste="clearError(index), checkError(qr, index)"
                  :ref="'input-qrcode-' + index"
                  readonly
                  :class="[qr.error && qr.error != 'used' ? 'text-danger' : '']"
                />

                <font-awesome-icon
                  icon="times"
                  v-if="qr.code != '' && qr.error && qr.error != 'used'"
                />

                <font-awesome-icon
                  icon="check"
                  v-else-if="qr.code != '' && qr.error == 'used'"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[
            'footer-panel',
            qrList.length > 4 ? 'position-relative bottom-0' : ''
          ]"
        >
          <div class="content-between flex-column">
            <b-button
              variant="link"
              class="mh-57"
              @click="$router.push('/profile')"
            >
              <font-awesome-icon icon="home" class="f-2xl text-white" />
            </b-button>
            <div class="text-center f-md">กลับไปหน้าหลัก</div>
          </div>
          <div class="content-between flex-column">
            <b-button variant="link" @click="reSendCode()">
              <div class="qr-code-scan">
                <font-awesome-icon icon="qrcode" class="f-2xl text-white" />
              </div>
            </b-button>

            <div class="text-center f-md">ส่งรหัสเพิ่ม</div>
          </div>
          <div class="content-between flex-column">
            <b-button
              variant="link"
              class="mh-57"
              @click="$router.push('/reward?tabIndex=1')"
            >
              <font-awesome-icon icon="gifts" class="f-2xl text-white" />
            </b-button>
            <div class="text-center f-md">รางวัลที่แลกแล้ว</div>
          </div>
        </div>
      </section>
    </div>
    <ModalError ref="ModalError" :text="errorMesasge" />
  </div>
</template>

<script>
import jsQR from "jsqr";
import Loading from "@/components/other/Loading";
import ModalError from "@/components/alert-modal/ModalError.vue";
const earnReward = require("@/assets/images/earn-reward.png");
const earnError = require("@/assets/images/earn-error.png");

import moment from "moment";
var countTime = null;
export default {
  components: { Loading, ModalError },
  props: {
    profile: {
      required: true
    },
    redeem_id: {
      required: true
    },
    product_id: {
      required: true
    },
    bannerImage: {
      required: true
    },
    type: {
      required: false
    }
  },
  data() {
    return {
      countSubmit: 0,
      Image: Image,
      earnReward,
      earnError,
      errorImage:
        this.$store.state.domain +
        this.$store.state.theme.themeTransactionCodeError,
      earnImage:
        this.$store.state.domain +
        this.$store.state.theme.themeTransactionCodeEarn,
      point_total: this.profile.total_point,
      detail: {},
      date: "",
      qrList: [{ code: "", error: false, errorWithMessage: false }],
      isSend: false,
      isLoading: false,
      modal: false,
      error: false,
      errorMesasge: "",
      errorList: [],
      response: {
        point: 0,
        point_all: 0
      },
      disableTimeButton: false,
      timeCountDown: 60
    };
  },
  beforeDestroy() {
    this.qrList = [{ code: "", error: false, errorWithMessage: false }];
    this.isLoading = false;
    this.modal = false;
    this.error = false;
    this.errorMesasge = "";
    this.errorList = [];
  },
  created() {
    let getTime = window.localStorage.getItem("button-error");

    if (getTime && 60 - moment().diff(getTime, "seconds") > 0) {
      this.countDown();
    }
    if (this.$route.query.code) {
      // this.redeem_id = this.$route.query.code;
      this.qrList[0].code = this.$route.query.code;

      this.$router.replace({ path: "/transaction" });
      // this.confirmSendCode(this.qrList[0].code);
    }
  },
  computed: {
    disableButton() {
      return this.qrList.some(el => el.errorWithMessage == true);
    },
    disableAddButton() {
      return this.qrList.some(el => el.code == "");
    },
    countEarnReward() {
      return (
        this.qrList.filter(el => el.code != "").length - this.errorList.length
      );
    },
    device() {
      var ua = navigator.userAgent.toLowerCase();
      var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      return isAndroid;
    }
  },
  watch: {
    profile(val) {
      this.point_total = val.total_point;
    }
  },
  methods: {
    openQR() {
      window.location = "https://line.me/R/nv/QRCodeReader";
    },
    countDown() {
      let getTime = window.localStorage.getItem("button-error");
      this.disableTimeButton = true;
      // this.checkTimeError();
      countTime = setInterval(() => {
        if (this.timeCountDown <= 0) {
          clearInterval(countTime);
          window.localStorage.removeItem("button-error");
          this.timeCountDown = 60;

          this.disableTimeButton = false;
        }
        this.timeCountDown = 60 - moment().diff(getTime, "seconds");
      }, 1000);
    },
    reSendCode() {
      this.modal = false;
      this.isSend = false;
      this.qrList = [{ code: "", error: false, errorWithMessage: false }];
    },
    clearError(index) {
      this.qrList[index].error = false;
      this.qrList[index].errorWithMessage = false;
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },

    deleteQrList(index) {
      if (this.qrList.length == 1) {
        return (this.qrList[0].code = "");
      }
      return this.qrList.splice(index, 1);
    },
    async addQrList() {
      if (this.qrList.some(el => el.code == "")) return;
      this.qrList.push({ code: "", error: false, errorWithMessage: false });
    },
    checkError(val, index) {
      const res = this.qrList.find(
        (el, i) => el.code == val.code && i != index
      );

      if (res && val.code != "")
        return (this.qrList[index].errorWithMessage = true);
      else this.qrList[index].errorWithMessage = false;
    },
    async setQrCode(index, result) {
      this.clearError(index);
      let string = result;
      let splitString = string.split("/");
      if (splitString.length > 1) {
        result = splitString[splitString.length - 1];
      }
      if (this.qrList.find(el => el.code == result)) {
        this.errorMesasge = "กรอกโค้ดนี้แล้ว";

        return;
      }

      if (index != null || index != undefined) {
        this.qrList[index].code = result;
        return (this.qrList = [...this.qrList]);
      } else {
        let qrObj = this.qrList;
        let index = qrObj.findIndex(el => el.code == "");
        if (index != -1) {
          qrObj[index].code = result;
          this.qrList = [...qrObj];
        } else this.qrList.push({ code: result, error: false });
      }
    },
    async scanAndConfirm() {
      try {
        const result = await this.$liff.scanCode();
        if (result.value) {
          let string = result.value;
          let splitString = string.split("/");
          if (splitString.length > 1) {
            result.value = splitString[splitString.length - 1];
          }
          this.confirmSendCode(result.value);
        }
      } catch (error) {
        console.error("scanCodeV2", error);
        window.alert(error);
      }
    },
    readURL(input, index) {
      input = input.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        const that = this;
        reader.onload = function (e) {
          that.decodeQrFromBase64(e, index);
        };

        reader.readAsDataURL(input.files[0]);
      }
    },
    decodeQrFromBase64(e, index) {
      const that = this;
      const width = 400;
      const height = 400;

      const qr = document.createElement("img");
      qr.src = e.target.result;

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const context = canvas.getContext("2d");

      if (context) {
        qr.onload = function () {
          context.drawImage(qr, 0, 0, width, height);
          const pixels = context.getImageData(0, 0, width, height);
          const imageData = pixels.data;

          const code = jsQR(imageData, 400, 400);

          if (code) {
            console.log("Found QR code", code);
            that.setQrCode(index, code.data);
          }
        };
      }
    },
    async confirmSendCode(value) {
      try {
        if (!this.errorImage) {
          const theme = await this.$store.dispatch("getTheme");
          this.errorImage =
            theme.themeLogoDomain + theme.result.themeTransactionCodeError;
          this.earnImage =
            theme.themeLogoDomain + theme.result.themeTransactionCodeEarn;
        }
        if (this.qrList.some(el => el.errorWithMessage == true) && !value) {
          return;
        }
        if (this.qrList.every(el => el.code == "") && !value) {
          this.errorMesasge = "กรุณากรอกโค้ดอย่างน้อย 1 โค้ด";
          this.$refs.ModalError.show();
          return "";
        }
        this.isLoading = true;
        this.error = false;
        this.errorMesasge = "";
        this.errorList = [];
        let payload = {};
        this.qrList = this.qrList.filter(el => el.code != "");
        let qrList = this.qrList.map(ele => {
          return { code: ele.code };
        });
        payload = {
          branch_id: 254,
          redeem_id: this.redeem_id,
          redeem_type_id: 1,
          product_id: this.product_id,
          transaction_code: qrList
        };

        const response = await this.$axios.post(
          this.$nissinTransaction,
          payload
        );

        if (response.result == 0) {
          if (this.countSubmit == 5) {
            return this.checkTimeError();
          } else this.countSubmit += 1;
          this.error = true;
          this.errorMesasge = response.message;
          if (!response.detail) {
            this.errorList = this.qrList;
          } else {
            this.errorList = response.detail;
            for (const qr of this.qrList) {
              if (this.errorList.filter(el => el.code == qr.code).length > 0) {
                qr.error = true;
              } else {
                qr.error = "used";
              }
            }
          }
        } else {
          this.countSubmit = 0;
          this.point_total = response.detail.point_all;
          this.response = response.detail;
          for (const qr of this.qrList) {
            qr.error = "used";
          }
          await this.$store.dispatch("refreshUserProfile");
        }
        this.modal = true;
        this.isLoading = false;
        this.isSend = true;
      } catch (error) {
        this.isLoading = false;
        window.alert(error);
      }
      countTime = setTimeout(() => {
        this.disableTimeButton = false;
        clearTimeout(countTime);
      }, 60000);
    },
    modalTermsOpen() {
      // this.$router.push("/terms");
      this.$EventBus.$emit("showModal", "term");
      // this.$refs.modalTerms.show();
    },
    checkTimeError() {
      this.disableTimeButton = true;
      this.countSubmit = 0;
      this.error = true;
      this.modal = true;
      this.errorMesasge = "คุณกรอกรหัสผิดเกิน 5 ครั้ง กรุณารอ 1 นาที";
      window.localStorage.setItem("button-error", moment());
      this.countDown();
      return (this.isLoading = false);
    }
  }
};
</script>

<style lang="scss" scoped>
.background-earn {
  background: #00000036;
  border-radius: 5px;
  padding: 5px 0px;
}
.desc {
  background-color: var(--primary-color);
  margin-top: 1rem;
  border-radius: 5px;
}
.banner-image {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px -4px black;
  object-fit: cover;
}
.shadow-box {
  box-shadow: 0px 0px 10px -4px var(--primary-color);
}
.border-box {
  width: 100%;
  /* height: auto; */
  background-color: var(--primary-color);
  min-height: 75px;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: var(--font-primary-color);
  align-items: center;

  padding: 0.5rem;
  .point {
    font-size: 1.75rem;
    font-weight: bold;
  }
  .gift-icon {
    border-radius: 50%;
    box-shadow: 0px 0px 18px -9px black;
    font-size: 1.25rem;
    padding: 0.25rem 0.5rem;
  }
}
.mh-57 {
  min-height: 57px;
}
.qr-code-scan {
  padding: 8px 10px;
  margin: 0 5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      0deg,
      var(--font-primary-color),
      var(--font-primary-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--font-primary-color) 75%
    ),
    linear-gradient(
      90deg,
      var(--font-primary-color),
      var(--font-primary-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--font-primary-color) 75%
    ),
    linear-gradient(
      180deg,
      var(--font-primary-color),
      var(--font-primary-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--font-primary-color) 75%
    ),
    linear-gradient(
      270deg,
      var(--font-primary-color),
      var(--font-primary-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--font-primary-color) 75%
    );
}
.btn-scan {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  display: inline-flex;
}
.gap-2 {
  gap: 0.25rem;
}
.rounded-info {
  border-radius: 50%;
  border: 1px solid var(--font-primary-color);
  padding: 0px 6px;
  /* height: 20px; */
  font-size: var(--text-sm);
  align-items: center;
  display: flex;
}
section div.qr-rows:not(:first-of-type) {
  margin-top: 0.75rem;
}
.qr-rows {
  display: flex;
  align-items: center;

  .input-qr {
    border: 1px solid white;
    border-radius: 1.5rem;
    padding: 5px 10px;
    background: white;
    display: flex;
    align-items: center;
    width: 100%;
    input {
      border: none;
      width: 100%;
    }
    svg {
      &[data-icon="times"] {
        color: red;
        margin-left: auto;
      }
      color: green;
    }
  }
}
.color-primary {
  color: var(--primary-color);
}
.btn-round-icon {
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  // margin-top: 0.5rem;
  font-size: var(--text-md);
}
.qr-list-container {
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 5px;
}
.btn-confirm-primary {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  min-width: 50%;
}
::v-deep #modal-redeem-code .modal-content {
  background-color: transparent;
  border: none;

  .modal-image {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    border-radius: 0.5rem 0.5rem 0 0;
    // box-shadow: 0px 0px 10px -4px black;
  }
  .modal-body {
    line-height: 1.5;
  }
  .modal-panel {
    background-color: var(--primary-color);
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 10px;
    text-align: center;
    color: var(--font-primary-color);
    .point {
      font-size: 2.25rem;
      font-weight: bold;
    }
  }
}

.footer-panel {
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 1.5rem;
  left: 0;
  width: 100%;
  margin-top: 1rem;
  color: white;
  flex-wrap: wrap;
  > div {
    text-align: center;

    width: 33.33%;
  }
  .btn-circle {
    border-radius: 50%;
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
    height: 50px;
    width: 50px;
    background-color: var(--primary-color);
    color: var(--font-primary-color);
    border-radius: 50%;
  }
}
.reward-transaction-desc {
  padding: 10px;
  background: rgb(230, 0, 50);
  margin: 1rem 0;
  text-align: center;
  color: var(--font-primary-color);
  border-radius: 0.5rem;
  box-shadow: 0 0 10px -4px #000;
}
::v-deep .modal-backdrop {
  opacity: 0.75;
}
.f-48 {
  font-size: 48px;
}
.bottom {
  position: fixed;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);
}
.container-input-code {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // overflow-y: auto;
  height: calc(100vh - var(--margin-navbar));
}
.t-nowrap {
  white-space: nowrap;
}
.bottom-0 {
  bottom: 0 !important;
}
.letter-spacing {
  letter-spacing: 0.3px;
}
@media screen and (min-width: 420px) {
  .letter-spacing {
    letter-spacing: 0px;
  }
}
.bg-banner {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  svg {
    font-size: 8rem;
  }
}
</style>

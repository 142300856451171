<template>
  <b-card class="border-none rounded-xl px-3 shadow-sm card-reward" no-body>
    <div class="text-center mt-3">
      <template v-if="getRewardList[count].random_reward_item_type != 1">
        <b class="f-xl">ยินดีด้วยคุณได้รับรางวัล</b>
        <div class="text-gray mt-2">
          <small>
            {{ getRewardList[count].redeem_code }}
            <span class="text-success">
              :
              <font-awesome-icon
                icon="check-circle"
                class="mx-1 text-success"
              />ได้รับของรางวัล
            </span>
          </small>
        </div>
      </template>
      <template v-else>
        <b class="f-xl">คุณไม่ได้รับรางวัล</b>
        <br />
        <div class="text-gray mt-2">
          <small
            >{{ getRewardList[count].redeem_code }}
            <span class="text-danger">
              :
              <font-awesome-icon
                icon="check-circle"
                class="mx-1 text-danger"
              />คุณไม่ได้รับรางวัล
            </span>
          </small>
        </div>
      </template>
    </div>
    <div class="rounded-xl p-3 overflow-hidden contant-reward">
      <div class="reward-image mb-3">
        <b-img
          :src="rewardImage(getRewardList[count])"
          @error="handleImageSrc"
          class="image-reward"
        ></b-img>
      </div>
      <p
        class="text-center mt-3 f-md"
        id="previewMessage"
        v-if="getRewardList[count].random_reward_item_type != 1"
      >
        {{
          getRewardList[count].random_reward_item_type == 4
            ? `คุณได้รับ ${getRewardList[count].point} Point
          คะแนนสะสมของคุณปัจจุบัน
          ${point_total.toLocaleString()} Point`
            : getRewardList[count].item_name
        }}
      </p>
      <p class="text-center mt-3 f-md" id="previewMessage" v-else>
        {{
          `เสียใจด้วยนะ คุณ ${$store.state.shortProfile.first_name_th} ${$store.state.shortProfile.last_name_th} ไม่ได้รับของรางวัลไว้โอกาสหน้า`
        }}
      </p>
      <div class="text-center">
        <b-button
          v-if="countList.length - (count + 1) != 0"
          pill
          variant="confirm-primary"
          class="shadow-box mb-2 mt-0 btn-open-box"
          @click="$emit('nextBox')"
        >
          {{ `เปิดกล่องต่อไป ${this.count + 1}/${this.countList.length}` }}
        </b-button>
        <template v-else>
          <b-button
            v-if="countList.length == 1"
            pill
            variant="confirm-primary"
            class="shadow-box mb-2 mt-0 btn-open-box"
            @click="$router.push('/reward-transaction')"
          >
            <font-awesome-icon icon="gifts" class="mx-2" /> ดูของรางวัล
          </b-button>
          <b-button
            v-else
            pill
            variant="confirm-primary"
            class="shadow-box mb-2 mt-0 btn-open-box"
            @click="$emit('lastBox')"
          >
            ต่อไป
          </b-button>
        </template>
        <div
          class="text-gray cursor-pointer"
          v-if="countList.length - (count + 1) != 0"
          @click="$emit('openAll')"
        >
          <small>
            <u>เปิดทั้งหมด ({{ countList.length - (count + 1) }})</u></small
          >
        </div>
        <div v-else style="height: 24px"></div>
        <div
          class="footer-panel"
          v-if="countList.length - (count + 1) == 0 && countList.length == 1"
        >
          <div>
            <b-button
              variant="circle"
              class="shadow-box"
              @click="$emit('resend')"
            >
              <div class="qr-code-scan p-0 m-0">
                <font-awesome-icon icon="qrcode" class="f-lg" />
              </div>
            </b-button>

            <div class="text-center f-sm">ส่งรหัสเพิ่ม</div>
          </div>
          <div>
            <b-button
              variant="circle"
              class="shadow-box"
              @click="$router.push('/profile')"
            >
              <font-awesome-icon icon="home" class="f-xl" />
            </b-button>
            <div class="text-center f-sm">กลับไปหน้าหลัก</div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    countList: { required: true },
    getRewardList: { required: true },
    count: { required: true },
    point_total: { required: true }
  },
  data() {
    return {
      profile: {
        point: 0
      }
    };
  },
  computed: {},
  mounted() {
    if (this.getRewardList[this.count].random_reward_item_type == 4) {
      // this.getPoint();
      this.$emit("updatePoint", this.getRewardList[this.count].point);
    }
  },
  methods: {
    rewardImage(items) {
      if (items) {
        if (items.random_reward_item_type == 4)
          return require("@/assets/images/point.png");
        if (items.random_reward_item_type == 1)
          return require("@/assets/images/Fail.png");
        if (items.item_img) return items.item_img;
        return require("@/assets/images/default-image.png");
      }
      return require("@/assets/images/default-image.png");
    },
    async getPoint() {
      const profile = await this.$store.dispatch("getUserApi");
      this.profile = profile;
    }
  }
};
</script>

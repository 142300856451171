<template>
  <div>
    <Loading v-if="isLoading" />

    <div>
      <template v-if="hasRandomReward">
        <div class="px-3 py-4">
          <OpenBox
            :countList="getRewardList"
            :getRewardList="getRewardList"
            :point_total="point_total"
            @updatePoint="updatePoint"
            @resend="resend"
          />
        </div>
      </template>
      <template v-else>
        <b-img
          class="banner-image"
          :src="
            $store.state.domain + $store.state.theme.themeTransactionCodeBanner
          "
          fluid
          alt="Responsive image"
        ></b-img>

        <div class="px-3 py-2">
          <div class="text-center p-3">
            <b-button variant="scan" pill @click="openQR">
              <div class="qr-code-scan">
                <font-awesome-icon icon="qrcode" />
              </div>
              SCAN</b-button
            >
          </div>
          <section class="px-3">
            <div class="f-md d-flex align-items-center mb-2">
              กรอกรหัสเพื่อรับคะแนน
              <font-awesome-icon icon="info-circle" class="ml-2" />
            </div>

            <div class="qr-list-container">
              <div
                v-for="(qr, index) of qrList"
                :key="'qrcode-' + index"
                class="mb-2"
              >
                <div class="qr-rows">
                  <div class="input-qr shadow-sm">
                    <input
                      placeholder="Code"
                      type="text"
                      v-model.trim="qr.code"
                      @keyup="
                        clearError(index),
                          checkError(qr, index),
                          handleEnter($event, index)
                      "
                      @input="clearError(index), checkError(qr, index)"
                      @paste="clearError(index), checkError(qr, index)"
                      :ref="'input-qrcode-' + index"
                      :class="[
                        qr.error && qr.error != 'used' ? 'text-danger' : ''
                      ]"
                    />

                    <font-awesome-icon
                      icon="times"
                      v-if="qr.code != '' && qr.error && qr.error != 'used'"
                    />

                    <font-awesome-icon
                      icon="check"
                      v-else-if="qr.code != '' && qr.error == 'used'"
                    />
                    <div
                      v-if="qr.errorWithMessage"
                      class="text-danger t-nowrap"
                    >
                      โค้ดซ้ำ
                    </div>
                  </div>
                  <label :for="'files' + index" class="btn mb-0">
                    <font-awesome-icon
                      icon="camera"
                      size="lg"
                      class="color-primary cursor-pointer"
                  /></label>

                  <input
                    type="file"
                    :id="'files' + +index"
                    @change="readURL($event, index)"
                    style="visibility: hidden; position: absolute"
                    accept="image/*"
                    :capture="device"
                  />
                </div>
              </div>
            </div>
            <div class="text-center" v-show="qrList.length < 6">
              <b-button
                variant="round-icon"
                class="shadow-box"
                @click="addQrList()"
                :disabled="disableAddButton"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
            </div>
            <div class="text-center mt-3">
              <b-button
                pill
                variant="confirm-primary"
                class="shadow-box mt-0"
                @click="confirmSendCode()"
                :disabled="disableButton || disableTimeButton"
              >
                {{
                  disableTimeButton
                    ? `กรุณารอสักครู่ ... ${timeCountDown}`
                    : "ยืนยันการส่งรหัส"
                }}
              </b-button>
              <div class="f-sm mt-2 cursor-pointer" @click="modalTermsOpen">
                <u>ข้อกำหนดและเงื่อนไข</u>
              </div>
            </div>
          </section>
        </div>
      </template>
    </div>
    <b-modal
      id="modal-redeem-code"
      class=""
      v-model="modal"
      centered
      hide-footer
      hide-header
    >
      <!-- <div class="text-center error-image" v-if="error">
          <font-awesome-icon icon="times-circle" size="5x" style="color: red" />
        </div> -->
      <b-img
        class="modal-image"
        :src="error ? errorImage : earnImage"
        fluid
        alt="Responsive image"
      ></b-img>
      <div v-if="!error">
        <div class="modal-panel">
          <div>คุณได้รับ Point</div>
          <div class="point">{{ response.point | numeral("0,0") }}</div>
          <div class="f-sm">
            คะแนนสะสมของคุณปัจจุบัน
            {{ response.point_all | numeral("0,0") }} Point
          </div>
        </div>
        <div class="text-center">
          <b-button
            pill
            variant="confirm-primary"
            class="shadow-box"
            @click="$router.push('/reward')"
          >
            <font-awesome-icon icon="gift" class="mr-2" /> แลกของรางวัล
          </b-button>
        </div>
      </div>
      <div v-else class="text-center">
        <div class="modal-panel py-4">{{ errorMesasge }}</div>
        <b-button
          pill
          variant="confirm-primary"
          class="shadow-box"
          @click="modal = false"
        >
          <font-awesome-icon icon="gift" class="mr-2" /> ส่งรหัสใหม่
        </b-button>
      </div>
      <div class="footer-panel">
        <div>
          <b-button variant="circle" class="shadow-box" @click="reSendCode()">
            <div class="qr-code-scan p-0 m-0">
              <font-awesome-icon icon="qrcode" class="f-lg" />
            </div>
          </b-button>

          <div class="text-center f-xs">ส่งรหัสเพิ่ม</div>
        </div>
        <div>
          <b-button
            variant="circle"
            class="shadow-box"
            @click="$router.push('/profile')"
          >
            <font-awesome-icon icon="home" class="f-xl" />
          </b-button>
          <div class="text-center f-xs">กลับไปหน้าหลัก</div>
        </div>
        <!-- <div>
          <b-button
            variant="circle"
            class="shadow-box"
            @click="$router.push('/reward?tabIndex=1')"
          >
            <font-awesome-icon icon="gifts" class="f-xl" />
          </b-button>
          <div class="text-center f-xs">รางวัลที่แลกแล้ว</div>
        </div> -->
      </div>
    </b-modal>
    <ModalError ref="ModalError" :text="errorMesasge" />
  </div>
</template>

<script>
import jsQR from "jsqr";

import Loading from "@/components/other/Loading";
import ModalError from "@/components/alert-modal/ModalError.vue";
import moment from "moment";
import OpenBox from "./detail";

var countTime = null;
export default {
  components: { Loading, ModalError, OpenBox },
  props: {
    profile: {
      required: true
    }
  },
  data() {
    return {
      countSubmit: 0,
      Image: Image,
      errorImage:
        this.$store.state.domain +
        this.$store.state.theme.themeTransactionCodeError,
      earnImage:
        this.$store.state.domain +
        this.$store.state.theme.themeTransactionCodeEarn,
      point_total: this.profile.total_point,
      detail: {},
      date: "",
      qrList: [{ code: "", error: false, errorWithMessage: false }],

      isLoading: false,
      modal: false,
      error: false,
      errorMesasge: "",
      errorList: [],
      response: {
        point: 0,
        point_all: 0
      },
      disableTimeButton: false,
      timeCountDown: 60,
      hasRandomReward: false,
      getRewardList: []
    };
  },
  beforeDestroy() {
    this.qrList = [{ code: "", error: false, errorWithMessage: false }];
    this.isLoading = false;
    this.modal = false;
    this.error = false;
    this.errorMesasge = "";
    this.errorList = [];
  },

  async mounted() {
    let getTime = window.localStorage.getItem("button-error");

    if (getTime && 60 - moment().diff(getTime, "seconds") > 0) {
      this.countDown();
    }

    if (this.$route.query.code && this.profile.user_guid) {
      window.localStorage.setItem("code", this.$route.query.code);
      this.$router.replace({ path: "/transaction" });
      this.qrList[0].code = window.localStorage.getItem("code");
      window.localStorage.removeItem("code");
      this.confirmSendCode(this.qrList[0].code);
    } else if (window.localStorage.getItem("code")) {
      this.qrList[0].code = window.localStorage.getItem("code");
      window.localStorage.removeItem("code");
      this.confirmSendCode(this.qrList[0].code);
    }
  },
  computed: {
    disableButton() {
      return this.qrList.some(el => el.errorWithMessage == true);
    },
    disableAddButton() {
      return this.qrList.some(el => el.code == "");
    },
    device() {
      var ua = navigator.userAgent.toLowerCase();
      var isAndroid = ua.indexOf("android") > -1; //&& ua.indexOf("mobile");
      return isAndroid;
    }
  },
  watch: {
    profile(val) {
      this.point_total = val.total_point;
    }
  },
  methods: {
    openQR() {
      window.location = "https://line.me/R/nv/QRCodeReader";
    },
    countDown() {
      let getTime = window.localStorage.getItem("button-error");
      this.disableTimeButton = true;
      // this.checkTimeError();
      countTime = setInterval(() => {
        if (this.timeCountDown <= 0) {
          clearInterval(countTime);
          window.localStorage.removeItem("button-error");
          this.timeCountDown = 60;

          this.disableTimeButton = false;
        }
        this.timeCountDown = 60 - moment().diff(getTime, "seconds");
      }, 1000);
    },
    reSendCode() {
      this.modal = false;
      this.qrList = [{ code: "", error: false, errorWithMessage: false }];
    },
    clearError(index) {
      this.qrList[index].error = false;
      this.qrList[index].errorWithMessage = false;
    },
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    },

    deleteQrList(index) {
      if (this.qrList.length == 1) {
        return (this.qrList[0].code = "");
      }
      return this.qrList.splice(index, 1);
    },
    async addQrList(index = this.qrList.length) {
      if (this.qrList.some(el => el.code == "") || this.qrList.length >= 6)
        return;

      this.qrList.push({ code: "", error: false, errorWithMessage: false });
      this.$nextTick(() =>
        this.$refs["input-qrcode-" + (parseInt(index) + 1)][0].focus()
      );
    },
    checkError(val, index) {
      const res = this.qrList.find(
        (el, i) => el.code == val.code && i != index
      );

      if (res && val.code != "")
        return (this.qrList[index].errorWithMessage = true);
      else this.qrList[index].errorWithMessage = false;
    },
    async setQrCode(index, result) {
      this.clearError(index);
      let string = result;
      let splitString = string.split("/");
      if (splitString.length > 1) {
        result = splitString[splitString.length - 1];
      }
      if (this.qrList.find(el => el.code == result)) {
        this.errorMesasge = "กรอกโค้ดนี้แล้ว";

        return;
      }

      if (index != null || index != undefined) {
        this.qrList[index].code = result;
        return (this.qrList = [...this.qrList]);
      } else {
        let qrObj = this.qrList;
        let index = qrObj.findIndex(el => el.code == "");
        if (index != -1) {
          qrObj[index].code = result;
          this.qrList = [...qrObj];
        } else this.qrList.push({ code: result, error: false });
      }
    },

    readURL(input, index) {
      input = input.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        const that = this;
        reader.onload = function (e) {
          that.decodeQrFromBase64(e, index);
        };

        reader.readAsDataURL(input.files[0]);
      }
    },
    decodeQrFromBase64(e, index) {
      const that = this;
      const width = 400;
      const height = 400;

      const qr = document.createElement("img");
      qr.src = e.target.result;

      const canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      const context = canvas.getContext("2d");

      if (context) {
        qr.onload = function () {
          context.drawImage(qr, 0, 0, width, height);
          const pixels = context.getImageData(0, 0, width, height);
          const imageData = pixels.data;

          const code = jsQR(imageData, 400, 400);

          if (code) {
            console.log("Found QR code", code);
            that.setQrCode(index, code.data);
          }
        };
      }
    },
    async confirmSendCode(value) {
      try {
        if (!this.errorImage) {
          const theme = await this.$store.dispatch("getTheme");
          this.errorImage =
            theme.themeLogoDomain + theme.result.themeTransactionCodeError;
          this.earnImage =
            theme.themeLogoDomain + theme.result.themeTransactionCodeEarn;
        }
        if (this.qrList.some(el => el.errorWithMessage == true) && !value) {
          return;
        }
        if (this.qrList.every(el => el.code == "") && !value) {
          this.errorMesasge = "กรุณากรอกโค้ดอย่างน้อย 1 โค้ด";
          this.$refs.ModalError.show();
          return "";
        }
        this.isLoading = true;
        this.error = false;
        this.errorMesasge = "";
        this.errorList = [];
        let payload = {};

        if (value) {
          payload = { transaction_code: [{ code: value }] };
        } else {
          let qrList = this.qrList.filter(el => el.code != "");
          payload = {
            transaction_code: qrList.map(el => {
              return { code: el.code };
            })
          };
        }
        let url = `${process.env.VUE_APP_API}/api/v1/TransactionCode/RedeemtransactionCode`;
        const response = await this.$axios.post(url, payload);
        this.hasRandomReward = response.detail.have_random_reward;
        if (response.detail.have_random_reward) {
          this.isLoading = false;
          this.getRewardList = response.detail.random_reward.sort(
            (a, b) => a.id - b.id
          );
        } else {
          if (response.result == 0) {
            if (this.countSubmit == 5) {
              return this.checkTimeError();
            } else this.countSubmit += 1;
            this.error = true;
            this.errorMesasge = response.message;
            if (typeof response.detail == "object") {
              this.errorList = response.detail;
              for (const qr of this.qrList) {
                if (this.errorList.includes(qr.code)) {
                  qr.error = true;
                } else {
                  qr.error = "used";
                }
              }
            }
          } else {
            this.countSubmit = 0;
            this.point_total = response.detail.point_all;
            this.response = response.detail;
            for (const qr of this.qrList) {
              qr.error = "used";
            }
            await this.$store.dispatch("refreshUserProfile");
          }
          this.modal = true;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
        window.alert(error);
      }
      countTime = setTimeout(() => {
        this.disableTimeButton = false;
        clearTimeout(countTime);
      }, 60000);
    },
    modalTermsOpen() {
      // this.$router.push("/terms");
      this.$EventBus.$emit("showModal", "term");
      // this.$refs.modalTerms.show();
    },
    checkTimeError() {
      this.disableTimeButton = true;
      this.countSubmit = 0;
      this.error = true;
      this.modal = true;
      this.errorMesasge = "คุณกรอกรหัสผิดเกิน 5 ครั้ง กรุณารอ 1 นาที";
      window.localStorage.setItem("button-error", moment());
      this.countDown();
      return (this.isLoading = false);
    },
    handleEnter(e, index) {
      if (e.keyCode == 13) this.addQrList(index);
    },
    resend() {
      this.hasRandomReward = false;
      this.getRewardList = [];
      this.qrList = [{ code: "", error: false, errorWithMessage: false }];
    },
    updatePoint(point) {
      this.point_total = this.point_total + Number(point);
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep html {
  overflow: hidden;
}
.banner-image {
  width: 100%;
  height: auto;
  // aspect-ratio: 16/9;
  // border-radius: 0.5rem;
  box-shadow: 1px 3px 10px -4px black;
}
.shadow-box {
  box-shadow: 0px 0px 10px -4px var(--primary-color);
}
.border-box {
  width: 100%;
  /* height: auto; */
  background-color: var(--primary-color);
  min-height: 75px;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: var(--font-primary-color);
  align-items: center;

  padding: 0.5rem;
  .point {
    font-size: 1.75rem;
    font-weight: bold;
  }
  .gift-icon {
    border-radius: 50%;
    box-shadow: 0px 0px 18px -9px black;
    font-size: 1.25rem;
    padding: 0.25rem 0.5rem;
  }
}
.qr-code-scan {
  padding: 0px 5px;
  margin: 0 5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      0deg,
      var(--font-primary-color),
      var(--font-primary-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--font-primary-color) 75%
    ),
    linear-gradient(
      90deg,
      var(--font-primary-color),
      var(--font-primary-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--font-primary-color) 75%
    ),
    linear-gradient(
      180deg,
      var(--font-primary-color),
      var(--font-primary-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--font-primary-color) 75%
    ),
    linear-gradient(
      270deg,
      var(--font-primary-color),
      var(--font-primary-color) 25%,
      transparent 25%,
      transparent 75%,
      var(--font-primary-color) 75%
    );
}
.btn-scan {
  background-color: var(--primary-color);
  color: var(--font-primary-color);
  display: inline-flex;
}
.gap-2 {
  gap: 0.25rem;
}
.rounded-info {
  border-radius: 50%;
  border: 1px solid var(--font-primary-color);
  padding: 0px 6px;
  /* height: 20px; */
  font-size: var(--text-sm);
  align-items: center;
  display: flex;
}
section div.qr-rows:not(:first-of-type) {
  margin-top: 0.75rem;
}
.qr-rows {
  display: flex;
  align-items: center;

  .input-qr {
    border: 1px solid #a6a6a6;
    border-radius: 1.5rem;
    padding: 5px 10px;
    background: white;
    display: flex;
    align-items: center;
    width: 100%;
    input {
      border: none;
      width: 100%;
    }
    svg {
      &[data-icon="times"] {
        color: red;
        margin-left: auto;
      }
      color: green;
    }
  }
}
.color-primary {
  color: var(--primary-color);
}
.btn-round-icon {
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  margin-top: 0.5rem;
  font-size: var(--text-md);
}
.qr-list-container {
  // max-height: 350px;
  // height: 350px;
  // overflow-y: auto;
  padding-bottom: 5px;
}

::v-deep #modal-redeem-code .modal-content {
  background-color: transparent;
  border: none;

  .modal-image {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
    // object-fit: cover;
    border-radius: 0.5rem 0.5rem 0 0;
    // box-shadow: 0px 0px 10px -4px black;
  }
  .modal-body {
    line-height: 1.5;
  }
  .modal-panel {
    background-color: var(--primary-color);
    border-radius: 0 0 0.5rem 0.5rem;
    padding: 10px;
    text-align: center;
    color: var(--font-primary-color);
    .point {
      font-size: 2.25rem;
      font-weight: bold;
    }
  }
}

.footer-panel {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  color: white;
  flex-wrap: wrap;

  > div {
    text-align: center;

    width: 33.33%;
  }
  .btn-circle {
    border-radius: 50%;
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
    height: 50px;
    width: 50px;
    background-color: var(--primary-color);
    color: var(--font-primary-color);
    border-radius: 50%;
  }
}
::v-deep .modal-backdrop {
  opacity: 0.75;
}
.error-image {
  padding: 1rem;
  background-color: rgb(82, 39, 184);
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}
.t-nowrap {
  white-space: nowrap;
}
</style>

<template>
  <b-modal
    dialog-class="mx-3"
    id="modal-redeem-code"
    content-class="rounded-xl shadow-sm"
    v-model="modal"
    :no-close-on-backdrop="skipBox"
    :no-close-on-esc="skipBox"
    centered
    hide-footer
    hide-header
  >
    <div
      class="text-center mb-3 d-flex align-items-center justify-content-center"
    >
      <div class="pl-3 f-xl"><b>ของรางวัลของคุณ</b></div>
      <!-- <font-awesome-icon
        v-show="!skipBox"
        icon="times"
        class="f-lg ml-auto"
        @click="modal = false"
      /> -->
    </div>
    <div class="row row-gap-2xl scrollable">
      <template v-for="items of getRewardList">
        <div class="col-4" :key="items.id + items.redeem_code + '-image'">
          <b-img class="w-100 h-auto" :src="imageSrc(items)"></b-img>
        </div>
        <div class="col-8 px-2 d" :key="items.id + items.redeem_code + '-code'">
          <div class="content-between f-md">
            <span class="text-gray text-nowrap d-inline-flex">
              <div class="redeem-code">{{ items.redeem_code }}</div>
            </span>
            <!-- <div>:</div> -->
            <div style="width: 125px; text-align: left">
              <span
                v-if="items.random_reward_item_type == 1"
                class="text-danger"
              >
                <font-awesome-icon
                  icon="times-circle"
                  class="mx-1 text-danger"
                />
                <b>ไม่ได้รับรางวัล</b>
              </span>
              <span v-else class="text-success">
                <font-awesome-icon
                  icon="check-circle"
                  class="mx-1 text-success"
                />
                <b>ได้รับรางวัล</b>
              </span>
            </div>
          </div>

          <div
            class="f-md mt-1 line-clamp-3 f-bold"
            v-if="
              items.random_reward_item_type == 2 ||
              items.random_reward_item_type == 3
            "
          >
            {{ items.item_name }}
          </div>
          <div
            class="f-md mt-1 f-bold"
            v-if="items.random_reward_item_type == 4"
          >
            คุณได้รับ {{ items.point }} Point
          </div>
        </div>
      </template>
    </div>
    <div class="text-center mt-3">
      <b-button
        pill
        variant="confirm-primary"
        class="shadow-box mt-0"
        @click="$router.push('/reward-transaction')"
      >
        รับรางวัล
      </b-button>
    </div>
    <div class="footer-panel" v-if="skipBox && getRewardList.length == 1">
      <div>
        <b-button variant="circle" class="shadow-box" @click="$emit('resend')">
          <div class="qr-code-scan p-0 m-0">
            <font-awesome-icon icon="qrcode" class="f-lg" />
          </div>
        </b-button>

        <div class="text-center f-xs">ส่งรหัสเพิ่ม</div>
      </div>
      <div>
        <b-button
          variant="circle"
          class="shadow-box"
          @click="$router.push('/profile')"
        >
          <font-awesome-icon icon="home" class="f-xl" />
        </b-button>
        <div class="text-center f-xs">กลับไปหน้าหลัก</div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    getRewardList: {
      required: true
    },
    skipBox: { required: false }
  },
  data() {
    return {
      modal: false
    };
  },
  methods: {
    show() {
      this.modal = true;
    },
    hide() {
      this.modal = false;
    },
    imageSrc(items) {
      return items.random_reward_item_type == 4
        ? require("@/assets/images/point.png")
        : items.random_reward_item_type == 1
        ? require("@/assets/images/Fail.png")
        : items.item_img || require("@/assets/images/default-image.png");
    }
  }
};
</script>

<style lang="scss" scoped>
.footer-panel {
  display: flex;
  justify-content: center;

  width: 100%;
  margin-top: 1rem;
  color: black;
  flex-wrap: wrap;
  position: absolute;
  left: 0;
  bottom: -80px;
  > div {
    text-align: center;

    width: 33.33%;
  }
  .btn-circle {
    border-radius: 50%;
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
    height: 50px;
    width: 50px;
    background-color: var(--primary-color);
    color: var(--font-primary-color);
    border-radius: 50%;
  }
}
.scrollable {
  height: 500px;
  overflow: auto;
}
.redeem-code {
  width: 105px;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  color: rgb(172, 172, 172);
}
</style>
